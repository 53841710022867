import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';

export interface Pagination {
  size: number;
  page: number;
}

@Component({
  selector: 'app-custom-paginator',
  standalone: true,
  imports: [DropdownModule, PaginatorModule, InputTextModule, TranslateModule],
  templateUrl: './custom-paginator.component.html',
  styleUrl: './custom-paginator.component.scss'
})
export class CustomPaginatorComponent {
  @Input({ required: true }) pagination: Pagination = {
    page: 0,
    size: 10
  };
  @Input() totalRecords = 0;
  @Input() options: number[] = [5, 10, 20];

  @Output() paginationChange = new EventEmitter<Pagination>();
  @Output() onChange = new EventEmitter();

  jumpTo = 1;

  onJumpPage() {
    if (this.jumpTo <= 0) {
      this.jumpTo = 1;
    }
    const maxPage = Math.ceil(this.totalRecords / this.pagination.size);

    if (this.jumpTo <= maxPage) {
      this.pagination.page = this.jumpTo - 1;
    } else {
      this.jumpTo = maxPage;
      this.pagination.page = maxPage - 1;
    }

    this.paginationChange.emit(this.pagination);
    this.onChange.emit();
  }

  onPageChange(event: any) {
    const { page } = event;
    this.pagination.page = page;

    this.paginationChange.emit(this.pagination);
    this.onChange.emit();
  }

  onRowsChange(event: DropdownChangeEvent) {
    this.pagination.size = event.value;
    this.pagination.page = 0;

    this.paginationChange.emit(this.pagination);
    this.onChange.emit();
  }
}
